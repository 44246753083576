import html2canvas from "html2canvas";
import { pageSize } from "./pageSize";

export const generateCertificate = async (studentDetails, backgroundImage) => {

    console.log("generateCertificate ////");
    console.log(studentDetails);
    
    const container = document.createElement("div");
    container.style.backgroundImage = `url(${backgroundImage})`;
    container.style.width = pageSize.container.width+"px"; // Dynamic width
    container.style.height = pageSize.container.height+"px"; // Dynamic height
    container.style.position = pageSize.container.position; // Dynamic position

    const number_text = document.createElement("div");
    number_text.textContent = `${studentDetails.number}`;
    number_text.style.position = pageSize.number.position; // Dynamic number_text position
    number_text.style.top = pageSize.number.top+"px"; // Dynamic top position
    number_text.style.left = pageSize.number.left+"px"; // Dynamic left position
    number_text.style.transform = pageSize.number.transform; // Dynamic transform
    number_text.style.color = pageSize.number.color; // Dynamic number_text color
    number_text.style.fontWeight = pageSize.number.fontWeight+"px"; // Dynamic font weight
    number_text.style.fontSize = pageSize.number.fontSize+"px"; // Dynamic font size
    container.appendChild(number_text);

    const name_text = document.createElement("div");
    name_text.textContent = `${studentDetails.name}`;
    name_text.style.position = pageSize.name.position; // Dynamic name_text position
    name_text.style.top = pageSize.name.top+"px"; // Dynamic top position
    name_text.style.left = pageSize.name.left; // Dynamic left position
    name_text.style.transform = pageSize.name.transform; // Dynamic transform
    name_text.style.color = pageSize.name.color; // Dynamic name_text color
    name_text.style.fontWeight = pageSize.name.fontWeight+"px"; // Dynamic font weight
    name_text.style.fontSize = pageSize.name.fontSize+"px"; // Dynamic font size
    container.appendChild(name_text);

    const date_text = document.createElement("div");
    date_text.textContent = `${studentDetails.date}`;
    date_text.style.position = pageSize.date.position; // Dynamic date_text position
    date_text.style.top = pageSize.date.top+"px"; // Dynamic top position
    date_text.style.left = pageSize.date.left+"px"; // Dynamic left position
    date_text.style.transform = pageSize.date.transform; // Dynamic transform
    date_text.style.color = pageSize.date.color; // Dynamic date_text color
    date_text.style.fontWeight = pageSize.date.fontWeight+"px"; // Dynamic font weight
    date_text.style.fontSize = pageSize.date.fontSize+"px"; // Dynamic font size
    container.appendChild(date_text);


    document.body.appendChild(container);
    const canvas = await html2canvas(container);
    document.body.removeChild(container);

    return canvas;
};
