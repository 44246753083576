import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { generateCertificate } from "../utils/certificateGenerator";
import { generateSVG } from "../utils/generateSVG";
import { convertSvgToPdf } from "../utils/convertSvgToPdf";

const ZipDownloader = ({ data, backgroundImage, setGenerated }) => {

    const svgToBlob = (svg) => {
        return new Blob([svg], { type: "image/svg+xml" });
    };

    const handleDownload = async () => {
        if (!data || data.length === 0) {
            alert("No data available to generate certificates!");
            return;
        }

        const zip = new JSZip();
        var count = 0
        for (const student of data) {
            console.log(student);
            setGenerated(++count)

            const canvas = await generateCertificate(student, backgroundImage);
            console.log(canvas);

            const image = canvas.toDataURL("image/png").split(",")[1];
            // console.log(image);

            zip.file(`${student.name}_certificate.png`, image, { base64: true });
        }

        zip.generateAsync({ type: "blob" }).then((blob) => {
            saveAs(blob, "certificates.zip");
        });
    };


    const handleSvgDownload = async () => {
        if (!data || data.length === 0) {
            alert("No data available to generate certificates!");
            return;
        }

        const zip = new JSZip();
        var count = 0
        data.forEach((student, index) => {
            console.log(student);
            setGenerated(++count)

            const svg = generateSVG(student, backgroundImage);
            console.log(svg);

            const blob = svgToBlob(svg);
            zip.file(`certificate-${student.name}.svg`, blob);
        });
        zip.generateAsync({ type: "blob" }).then((blob) => {
            saveAs(blob, "certificates.zip");
        })
    };


    const handlePdfDownload = async () => {
        if (!data || data.length === 0) {
            alert("No data available to generate certificates!");
            return;
        }

        const zip = new JSZip();
        let count = 0;

        for (const student of data) {
            try {
                console.log(student);

                // Generate SVG as a DOM element
                const svgString = generateSVG(student, backgroundImage); // Function generates SVG as string
                const svgElement = new DOMParser()
                    .parseFromString(svgString, "image/svg+xml")
                    .documentElement;

                // Convert SVG to PDF blob
                const pdfBlob = await convertSvgToPdf(svgElement);

                // Add PDF blob to ZIP file
                zip.file(`certificate-${student.name}.pdf`, pdfBlob);

                count++;
                setGenerated(count); // Update progress if needed
            } catch (error) {
                console.error(`Error generating PDF for ${student.name}:`, error);
            }
        }

        // Generate ZIP and trigger download
        zip.generateAsync({ type: "blob" }).then((blob) => {
            saveAs(blob, "certificates.zip");
        });
    };

    return (
        <div>
            <h2>Download Certificates</h2>
            <button onClick={handleDownload}>Download as ZIP (png)</button>
            <button onClick={handleSvgDownload}>Download as ZIP (svg)</button>
            <button onClick={handlePdfDownload}>Download as ZIP (pdf)</button>

        </div>
    );
};

export default ZipDownloader;
