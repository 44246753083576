export const pageSize = {
    container: {
        width: 3508,
        height: 2480,
        position: "relative",
    },
    // name: {
    //     position: "absolute",
    //     top: 1360,
    //     left: "50%",
    //     transform: "translate(-50%, -50%)",
    //     color: "black",
    //     fontWeight: "bold",
    //     fontSize: 85,
    // },

    name: {
        position: "absolute",
        top: 1360,
        left: "50%",
        transform: "translateX(-50%)",
        color: "black",
        fontWeight: "bold",
        fontSize: 85,
    },

    date: {
        position: "absolute",
        top: 2260,
        left: 665,
        transform: "translate(0%,0%)",
        color: "black",
        fontWeight: "bold",
        fontSize: 76,
    },
    number: {
        position: "absolute",
        top: 184,
        left: 1017,
        transform: "translate(0%,0%)",
        color: "black",
        fontWeight: "bold",
        fontSize: 75,
    },
  };