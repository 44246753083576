import React from "react";
import { pageSize } from "../utils/pageSize";
// import { generateSVG } from "../utils/generateSVG";

const CertificatePreview = ({ certificateDetails, backgroundImage }) => {
    const zoom = 0.2
    // const svgElement = generateSVG(certificateDetails && certificateDetails[0], backgroundImage)
    return (
        <div className="certificate-preview">
            <h2>Certificate Preview</h2>
            <div
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    width: pageSize.container.width * zoom + "px", // Dynamic width from pageSize
                    height: pageSize.container.height * zoom + "px", // Dynamic height from pageSize
                    position: pageSize.container.position, // Dynamic position from pageSize
                    margin: "0 auto"
                }}
            >

                <div
                    style={{
                        position: pageSize.number.position, // Dynamic position
                        top: pageSize.number.top * zoom + "px", // Dynamic top
                        left: pageSize.number.left * zoom + "px", // Dynamic left
                        color: pageSize.number.color, // Dynamic number color
                        fontWeight: pageSize.number.fontWeight, // Dynamic font weight
                        fontSize: pageSize.number.fontSize * zoom + "px", // Dynamic font size
                        transform: pageSize.number.transform, // Dynamic transform
                    }}
                >
                    {`${certificateDetails && certificateDetails[0]?.number}`}
                </div>
                <div
                    style={{
                        position: pageSize.name.position, // Dynamic position
                        top: pageSize.name.top * zoom + "px", // Dynamic top
                        left: pageSize.name.left, // Dynamic left
                        color: pageSize.name.color, // Dynamic name color
                        fontWeight: pageSize.name.fontWeight, // Dynamic font weight
                        fontSize: pageSize.name.fontSize * zoom + "px", // Dynamic font size
                        transform: pageSize.name.transform, // Dynamic transform
                    }}
                >
                    {`${certificateDetails && certificateDetails[0]?.name}`}
                </div>

                <div
                    style={{
                        position: pageSize.date.position, // Dynamic position
                        top: pageSize.date.top * zoom + "px", // Dynamic top
                        left: pageSize.date.left * zoom + "px", // Dynamic left
                        color: pageSize.date.color, // Dynamic date color
                        fontWeight: pageSize.date.fontWeight, // Dynamic font weight
                        fontSize: pageSize.date.fontSize * zoom + "px", // Dynamic font size
                        transform: pageSize.date.transform, // Dynamic transform
                    }}
                >
                    {`${certificateDetails && certificateDetails[0]?.date}`}
                </div>

            </div>
            {/* <div>
                {svgElement ? (
                    <div dangerouslySetInnerHTML={{ __html: svgElement }} />
                ) : (
                    <p>No SVG content available.</p>
                )}
            </div> */}
        </div>
    );
};

export default CertificatePreview;