import { jsPDF } from "jspdf";
import { svg2pdf } from "svg2pdf.js";

export async function convertSvgToPdf(svgElement) {
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "pt",
    format: [3508, 2480], // PDF dimensions
  });

  const svgString = new XMLSerializer().serializeToString(svgElement);

  const svgElementForCanvas = new DOMParser()
    .parseFromString(svgString, "image/svg+xml")
    .documentElement;

  const options = {
    xOffset: 0,
    yOffset: 0,
    width: 3508,
    height: 2480,
  };

  // Add SVG to PDF
  await svg2pdf(svgElementForCanvas, pdf, options);

  // Return the blob of the PDF
  return pdf.output("blob");
}
