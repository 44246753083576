import React, { useState, useEffect } from "react";
import DataUploader from "./components/DataUploader";
import CertificatePreview from "./components/CertificatePreview";
import ZipDownloader from "./components/ZipDownloader";
import "./App.css";
import bgImage from './sample-backround.jpeg';

function App() {
  const [data, setData] = useState(null); // To store the student details
  const [image, setImage] = useState(null); // To store the base64 image
  const [generated, setGenerated] = useState(0); // To store the base64 image
  console.log(data);



  const convertToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob(); // Fetch the image as a Blob
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result); // Resolve the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Convert Blob to Base64
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      return null;
    }
  };

  // Using useEffect to fetch the base64 image when the component mounts
  useEffect(() => {
    const fetchImage = async () => {
      const base64Image = await convertToBase64(bgImage);
      // const base64Image = await convertToBase64("https://test.api.stem-xpert.com/uploads/images/file-1727419824092.jpeg");
      setImage(base64Image); // Set the base64 image after conversion
    };

    fetchImage();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="App">
      <h1>Bulk Course Certificate Generator</h1>
      <DataUploader setData={setData} />
      {image && <CertificatePreview certificateDetails={data} backgroundImage={image} />}
      {image && <ZipDownloader data={data} backgroundImage={image} setGenerated={setGenerated}/>}
      <div>{generated}/{data?.length}</div>
    </div>
  );
}

export default App;
