import { pageSize } from "./pageSize";

export const generateSVG = (student,backgroundImage) => {
    
    return `
     <svg xmlns="http://www.w3.org/2000/svg" width="${parseInt(pageSize.container.width)}" height="${parseInt(pageSize.container.height)}" viewBox="0 0 ${parseInt(pageSize.container.width)} ${parseInt(pageSize.container.height)}">
        <!-- Background Image -->
        <image href="${backgroundImage}" x="0" y="0" width="${parseInt(pageSize.container.width+"px")}" height="${parseInt(pageSize.container.height+"px")}" />
        
        <!-- Text Element -->
        
        <text 
            x="${parseInt(pageSize.number.left+"px")}" 
            y="${parseInt(pageSize.number.top+pageSize.number.fontSize +"px")}" 
            text-anchor="start" 
            fill="${pageSize.number.color}" 
            font-size="${parseInt(pageSize.number.fontSize+"px")}" 
            font-weight="${pageSize.number.fontWeight}">
            ${student?.number}
        </text>

        <text 
            x="${pageSize.container.width/2}px" 
            y="${parseInt(pageSize.name.top+pageSize.name.fontSize +"px")}" 
            text-anchor="middle" 
            fill="${pageSize.name.color}" 
            font-size="${parseInt(pageSize.name.fontSize+"px")}" 
            font-weight="${pageSize.name.fontWeight}">
            ${student?.name}
        </text>

        <text 
            x="${parseInt(pageSize.date.left+"px")}" 
            y="${parseInt(pageSize.date.top+pageSize.date.fontSize +"px")}" 
            text-anchor="start" 
            fill="${pageSize.date.color}" 
            font-size="${parseInt(pageSize.date.fontSize+"px")}" 
            font-weight="${pageSize.date.fontWeight}">
            ${student?.date}
        </text>

    </svg>`;
    };
