import React, { useState } from "react";
import Papa from "papaparse";

const DataUploader = ({ setData }) => {
  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    Papa.parse(uploadedFile, {
      header: true,
      complete: (result) => {
        console.log(result);
        
        setData(result.data);
        alert("Data uploaded successfully!");
      },
    });
  };
  console.log(file);
  
  return (
    <div>
      <h2>Upload Student Data</h2>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
    </div>
  );
};

export default DataUploader;
